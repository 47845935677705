import React from 'react';
import Dropzone from 'react-dropzone'
import './index.scss';
import { Header, Icon, Image } from 'semantic-ui-react';
import { ButtonLink } from 'dyl-components';


const getColor = (props) => {
    if (props.isDragAccept) {
        return 'accept';
    }
    if (props.isDragReject) {
        return 'reject';
    }
    if (props.isDragActive) {
        return 'active';
    }
    return '';
}

const onRemove = (onChange, value, fileToRemove, name) => (e) => {
    const indexOfFileToRemoved = value.findIndex(file => file.path === fileToRemove);
    value.splice(indexOfFileToRemoved, 1);
    onChange(e, { name, value });
}

const onAdd = (onChange, onReject, name) => (accepted, rejected) => {
    onReject(rejected);
    onChange(null, { name, value: accepted });
}

const FileInput = ({
    hasBrowse,
    showIcon,
    size,
    color = 'black',
    onChange,
    files,
    name,
    accept,
    icon = 'file outline',
    dropzoneRef = React.createRef(),
    onReject = () => { },

    showImage
}) => (
    <Dropzone ref={dropzoneRef} onDrop={onAdd(onChange, onReject, name)} noClick noKeyboard accept={accept}>
        {({ getRootProps, getInputProps, isDragAccept, isDragActive, isDragReject }) => (
            <section>
                <div {...getRootProps(isDragAccept, isDragActive, isDragReject)} className={`DragAndDropZone DragAndDropZone--${getColor({ isDragAccept, isDragActive, isDragReject })}`}>
                    <input {...getInputProps()} />
                    {(files && files.length !== 0) ?
                        (!showImage ? (
                            files.map(file => (
                                <React.Fragment key={file.path}>
                                    <Header {...(size ? { size } : {})} color={color} icon className='FileHeader'>
                                        <Icon name={icon} />
                                        {file.path}
                                    </Header>
                                    <Icon circular color='grey' name='cancel' link onClick={onRemove(onChange, files, file.path, name)} className='FileRemoveButton' />
                                </React.Fragment>
                            ))
                        ) : files.map(file => (
                            <Image
                                style={{ paddingTop: "1em", paddingBottom: "1em" }}
                                src={file.path}
                                size='medium'
                                centered
                                label={{ 
                                    children: (
                                        <Icon
                                            className='fas fa-times-circle'
                                            color="red"
                                            inverted
                                            link
                                            onClick={onRemove(onChange, files, file.path, name)}
                                            size="big"
                                        />
                                    ),
                                    corner: "right",
                                    className: "FileInput__remove-image"
                                }}
                            />
                        )))
                        :
                        <React.Fragment>
                            <Header icon className='DragAndDropZone__header'>
                                {showIcon && <Icon name={icon} />}
                                {hasBrowse && <ButtonLink onClick={() => dropzoneRef.current.open()}>Browse</ButtonLink>} or Drag and Drop File(s) Here
                            </Header>
                        </React.Fragment>
                    }
                </div>
            </section>
        )}
    </Dropzone>
);

export default FileInput;
