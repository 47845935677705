import React, { useEffect, useState } from 'react';
import { Icon, Header } from 'semantic-ui-react';

import './index.scss';
import DateTimeUtils from 'dyl-components/utils/DateTimeUtils';

const VoicemailPlayback = ({ className = 'audio-playback', fileName = 'Untitled.mp3', link = '', onClose, border }) => {
    //unique className prop for multiple audio's on single page render
    let audio;
    const [playerButton, setPlayerButton] = useState(true);
    const [muteButton, setMuteButton] = useState(true);
    const [audioTime, setAudioTime] = useState(0);
    const [audioDuration, setAudioDuration] = useState(0);
    const [listenTracker, setListenTracker] = useState(null);

    useEffect(()=>{
        if(link){
            audio = document.querySelector(`.${className}`);
            audio.onloadedmetadata = () => {
                setAudioDuration(audio.duration)
            }
        }
        return audio;
    })

    useEffect(() => {
        if(link){
            audio.addEventListener("ended", endPlayback);
        }
        return () => {
            if(link){
                audio.removeEventListener("ended", endPlayback);
            }
        };
    });

    const endPlayback = () => {
        setPlayerButton(true);
        clearListenTrack();
        setAudioTime(0);
    }
   
    const setListenTrack = () => {
        if(!listenTracker){
            setListenTracker(window.setInterval(() => {
                setAudioTime((100*audio.currentTime) / audio.duration)
            }, 1));
        }
    }

    const clearListenTrack = () => {
        if(listenTracker){
            clearInterval(listenTracker)
            setListenTracker(null);
        }
    }

    const toggleAudio = () => {
        if (audio.paused) {
            audio.play();
            setListenTrack();
            setPlayerButton(false);
        } else {
            audio.pause();
            clearListenTrack();
            setPlayerButton(true);
        }
    }

    const onChangeSeek = (e) => {
        const rangeTime = Number(e.target.value);
        const time = (rangeTime * audio.duration) / 100;
        setAudioTime(rangeTime);
        audio.currentTime = time;
    }

    const toggleMute = () => {
        audio.muted = !audio.muted;
        setMuteButton(prevState => (
            !prevState
        ));
    }

    return (
        <div className={`audio-player${border ? '--border' : ''}`}>
            {link ?
                <>
                    { onClose &&
                        <div style={{ textAlign: 'right', marginBottom: 15 }} >
                            <Icon className='fa-solid fa-xmark' size='big' onClick={onClose} style={{cursor: 'pointer'}}/>
                        </div>
                    }
                    <div className='audio-header'>
                        <Header as='h3' style={{display: 'inline'}}>{fileName}</Header> 
                        <div style={{display: 'inline', float: 'right', marginBottom: 2, marginRight: 10}}>{DateTimeUtils.convertTimeUnits(audioTime, audioDuration)}/{DateTimeUtils.convertTimeUnits(audioDuration, audioDuration)}</div>
                    </div>
                    <audio className={className} src={link}></audio>
                    <div className="audio-controls">
                        {playerButton ?
                            <Icon 
                                className='fa-solid fa-circle-caret-right audio-button'
                                size='big'
                                onClick={toggleAudio}
                            />
                            :
                            <Icon 
                                className='fa-solid fa-circle-pause audio-button'
                                size='big'
                                onClick={toggleAudio}
                            />
                        }
                        <input type="range" class="audio-timeline" min="0" max="100" step=".00001" value={audioTime} onChange={(e) => onChangeSeek(e)}/>
                        {muteButton ?
                            <Icon 
                                className='fa-solid fa-volume audio-button'
                                size='big'
                                onClick={toggleMute}
                            />
                        :
                            <Icon 
                                className='fa-solid fa-volume-xmark audio-button'
                                size='big'
                                onClick={toggleMute}
                            />}
                    </div>
                    <div className="download-header">
                        <a href={link} className="audio-button" target="_blank"><b>Download</b></a>
                    </div>
                </>
                :
                <Header as='h3'>No audio file loaded</Header>
                }
        </div>
    )
};

export default VoicemailPlayback;
